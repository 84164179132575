/* eslint-disable func-names */
/* eslint-disable array-callback-return */
import { Button } from '@chakra-ui/react';
import axios from 'axios';
import React from 'react';

import AddFileWindow from '../../../components/add-file-window';
import Card from '../../../components/card';
import { FileCard } from '../../../components/file-card';
import { ITEM_COLORS } from '../../../constants';
import { styles } from './styles';

export const LoadingModeBlock = ({
  setElementsQueryResponse,
  currentFile,
  setCurrentFile,
  setLoadingStatus,
  handleProceedToView,
  setSheetInfo,
  loadingStatus,
  setImage,
  getInputProps,
  getRootProps,
}) => {
  const [progressValue, setProgressValue] = React.useState(0);

  const objectConvertion = (obj) => {
    const result = JSON.parse(obj);
    result.map((folder, idx) => {
      Object.assign(folder, { parentId: null });
      folder?.elements?.map((element) => {
        Object.assign(element, { parentId: folder?.id });
        if (ITEM_COLORS.length < idx + 1) {
          Object.assign(element, { elementColor: '#4831D4' });
        } else {
          Object.assign(element, { elementColor: ITEM_COLORS[idx] });
        }
        Object.assign(element, { type: 'element' });
        Object.assign(element, { id: element?.name });
        result.push(element);
      });
      // eslint-disable-next-line no-param-reassign
      delete folder?.elements;
    });
    setElementsQueryResponse(result);
    return result;
  };

  async function elementsOnGridRequest(url, data) {
    const formdata = new FormData();
    formdata.append('file', data);
    const responseResult = await axios({
      method: 'post',
      url,
      auth: {
        username: 'drawer',
        password: 'Y4AuMasf',
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          objectConvertion(response?.data);
        }
      })
      .catch(function (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        setLoadingStatus('error');
      });
    return responseResult;
  }

  // eslint-disable-next-line no-unused-vars
  async function renderGridRequest(url, data) {
    const formdata = new FormData();
    formdata.append('file', data);
    const responseResult = await axios({
      method: 'post',
      url,
      data: formdata,
      auth: {
        username: 'drawer',
        password: 'Y4AuMasf',
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          // eslint-disable-next-line no-console
          setTimeout(
            elementsOnGridRequest(
              `https://${
                process.env.REACT_APP_API_DEV_MODE === '1' ? 'dev-' : ''
              }draw-grid_relative_position.key4.com.ua/${
                process.env.REACT_APP_API_DEV_MODE === '1'
                  ? 'integrate_grid_lines_v3?scale_lines=false'
                  : 'integrate_grid_lines_v2'
              }`,
              currentFile[0],
            ),
            5000,
          );
        }
      })
      .catch(function (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        setLoadingStatus('error');
      });
    return responseResult;
  }

  async function sheetInfoRequest(url, data) {
    const formdata = new FormData();
    formdata.append('file', data);
    const responseResult = await axios({
      method: 'post',
      url,
      data: formdata,
      auth: {
        username: 'drawer',
        password: 'Y4AuMasf',
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          setSheetInfo(response.data);
        }
      })
      .catch(function (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        setLoadingStatus('error');
      });
    return responseResult;
  }

  async function imageDimensionsRequest(url, data) {
    const formdata = new FormData();
    formdata.append('file', data);
    const responseResult = await axios({
      responseType: 'arraybuffer',
      method: 'post',
      data: formdata,
      url,
      auth: {
        username: 'drawer',
        password: 'Y4AuMasf',
      },
    }).then(function (response) {
      if (response.status === 200) {
        const blob = new Blob([response.data], { type: 'image/jpeg' });
        const imageUrl = URL.createObjectURL(blob);
        const result = [imageUrl];
        setImage(result);
      }
    });
    return responseResult;
  }

  async function imagePreviewRequest(url, data) {
    const formdata = new FormData();

    formdata.append('file', data);
    const responseResult = await axios({
      method: 'post',
      responseType: 'arraybuffer',
      url,
      data: formdata,
      auth: {
        username: 'drawer',
        password: 'Y4AuMasf',
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          imageDimensionsRequest(
            `https://${
              process.env.REACT_APP_API_DEV_MODE === '1' ? 'dev-' : ''
            }draw-pdf_extract_components.key4.com.ua/parse_text_pdf_fitz/?page_number=0&preview_parsing_results=true&return_additional_info=false`,
            currentFile[0],
          );
          sheetInfoRequest(
            `https://${
              process.env.REACT_APP_API_DEV_MODE === '1' ? 'dev-' : ''
            }draw-sheet_components_json.key4.com.ua/sheet_components_full_yolo_v5`,
            currentFile[0],
          );
        }
      })
      .catch(function (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        setLoadingStatus('error');
      });
    return responseResult;
  }
  function updateCompletedCallsCount(data) {
    setProgressValue(JSON.parse(JSON.parse(data)?.data?.data)?.status);
  }

  const createSSE = () => {
    const sse = new EventSource(
      `https://${
        process.env.REACT_APP_API_DEV_MODE === '1' ? 'dev-' : ''
      }draw-progress_bar.key4.com.ua/stream_status/`,
    );
    sse.onmessage = (e) => updateCompletedCallsCount(e.data);
    sse.onerror = () => {
      setLoadingStatus('error');
      sse.close();
    };
    return () => {
      sse.close();
    };
  };

  React.useEffect(() => {
    if (currentFile.length > 0) {
      createSSE();
      imagePreviewRequest(
        `https://${
          process.env.REACT_APP_API_DEV_MODE === '1' ? 'dev-' : ''
        }draw-doors.key4.com.ua/preview`,
        currentFile[0],
      );
      renderGridRequest(
        `https://${
          process.env.REACT_APP_API_DEV_MODE === '1' ? 'dev-' : ''
        }draw-grid_relative_position.key4.com.ua/pdf_to_svg_convert_and_render_v2`,
        currentFile[0],
      );
    }
    if (currentFile.length === 0) {
      setLoadingStatus('loading');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFile]);

  return (
    <Card
      wrapperProps={{
        width: '100%',
        height: '89vh',
        padding: '1.563vw',
        flexDirection: 'column',
      }}
    >
      <div
        {...(currentFile.length ? null : { ...getRootProps() })}
        style={{
          opacity: currentFile.length ? 0.7 : 1,
          height: '100%',
        }}
      >
        <input {...getInputProps()} name='file' />
        <AddFileWindow
          loadingStatus={loadingStatus}
          wrapperProps={{
            width: '100%',
            height: '100%',
            cursor: currentFile.length ? 'no-drop' : 'pointer',
          }}
        />
      </div>
      {currentFile.length ? (
        <>
          <FileCard
            file={currentFile[0]}
            setCurrentFile={setCurrentFile}
            loadingStatus={loadingStatus}
            setLoadingStatus={setLoadingStatus}
            progressValue={progressValue}
          />
          <Button
            sx={styles.continueBtn}
            variant='solid'
            colorScheme='green'
            onClick={handleProceedToView}
            disabled={loadingStatus !== 'done'}
          >
            Continue
          </Button>
        </>
      ) : null}
    </Card>
  );
};
